@import "styles/core/_all.scss";
.container {
  position: absolute;
  left: 0;
  z-index: 1;
  width: 100%;
  overflow: hidden;
  bottom: 70px;
  height: 275px;
  @include media-breakpoint-up(md) {
    bottom: 60px;
    height: 400px;
  }
  @include media-breakpoint-up(lg) {
    bottom: 0;
  }
  @include media-breakpoint-up(xl) {
    height: 500px;
  }

  svg {
    width: 550px;
    @include x-align;
    top: 0;
    @include media-breakpoint-up(md) {
      width: 800px;
    }
    @include media-breakpoint-up(xl) {
      width: 1000px;
    }
  }

  .circleInnerProgress {
    z-index: 1;
    g {
      transform-origin: center center;
    }
    circle {
      fill: none;
      stroke: currentColor;
      stroke-width: 6;
      stroke-dasharray: 100;
      stroke-linecap: square;
      transition: all 1s ease-in-out;

      // &.play {
      //   animation-name: playCircleInnerProgress;
      //   animation-duration: 13s;
      //   animation-timing-function: linear;
      // }
      // &.movedByArrow {
      //   animation-duration: 1s;
      //   // animation-fill-mode: backwards;
      //   animation-direction: reverse;
      // }

      // @keyframes playCircleInnerProgress {
      //   0% {
      //     transform: rotate(0deg);
      //   }
      //   95% {
      //     transform: rotate(180deg);
      //     stroke: currentColor;
      //   }
      //   100% {
      //     transform: rotate(180deg);
      //     stroke: theme("colors.stone.700");
      //   }
      // }
    }
  }

  .inner {
    transform-origin: center center;
    transition: transform 1s ease;

    .circleMain {
      fill: none;
      stroke: currentColor;
      stroke-width: 6;
      transition: all 1s ease-in-out;
    }

    .circleInner {
      fill: none;
      stroke: theme("colors.stone.700");
      stroke-width: 6;
    }

    .circle {
      fill: theme("colors.stone.800");
      stroke: currentColor;
      stroke-width: 6;
      transition: all 1s ease-in-out;
      &.isActive {
        fill: currentColor;
      }
    }
  }
}
