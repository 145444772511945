@import "styles/core/_all.scss";
.container {
  overflow-x: hidden;
  position: relative;
  height: var(--app-height); // 100vh
  background-color: theme("colors.stone.900");

  .wrapper {
    // min-height: 650px;
    height: var(--app-height); // 100vh
    transition: transform 0.5s ease 1s;
    position: relative;
    overflow: hidden;
    background-color: theme("colors.stone.800");

    // @include media-breakpoint-up(md) {
    //   min-height: 850px;
    // }

    &.sidePageActive {
      transition: transform 0.5s ease;
      transform: scale(0.9);
    }
    .bg {
      display: flex;
      justify-content: space-around;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      span {
        width: 1px;
        height: 100%;
        background-color: theme("colors.stone.700");
        position: relative;
        &:nth-child(1) {
          &:after {
            animation-delay: 2s;
          }
        }

        &:nth-child(3) {
          &:after {
            animation-delay: 2.5s;
          }
        }

        @keyframes drop {
          0% {
            top: -50%;
          }

          100% {
            top: 110%;
          }
        }

        &:after {
          content: "";
          display: block;
          position: absolute;
          height: 15vh;
          width: 100%;
          top: -50%;
          left: 0;
          background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 75%, #ffffff 100%);
          animation: drop 7s 0s infinite;
          animation-fill-mode: forwards;
          animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
        }
      }
    }
    .background {
      position: absolute;
      top: 0;
      width: 100%;
      height: auto;
      opacity: 0.5;
      transition: color 0.5s ease;
      z-index: 1;
    }
  }
}
