@import "styles/core/_all.scss";
.container {
  // overflow-y: scroll;
  // overflow-x: hidden;
  position: absolute;
  top: 0;
  left: 0;
  background-color: theme("colors.stone.800");
  width: 100%;
  height: 100%;
  transition: transform 0.5s ease 0.5s;

  &.isActive {
    transform: translateX(0) !important;
  }
  .closeContainer {
    max-width: theme("maxWidth.screen-xl");
    margin: 0 auto;
    padding: 30px 30px 0 30px;

    @include media-breakpoint-up(md) {
      padding: 60px 60px 0 60px;
    }
    .close {
      background-color: theme("colors.stone.900");
      height: 30px;
      padding: 20px 20px;
      display: flex;
      align-items: center;
      gap: 5px;
      font-weight: 500;
      border-radius: 10px;
      z-index: 1;
      position: relative;
      svg,
      span {
        flex-shrink: 0;
      }
    }
  }
}
