@import "styles/core/_all.scss";
.swiperContainerWrapper {
  position: absolute;
  z-index: 3;
  bottom: 275px;
  transform: translateX(-50%);
  left: 50%;

  // height: 100%;
  // display: flex;
  // align-items: flex-end;
  // padding-bottom: 350px;
  @include media-breakpoint-up(md) {
    bottom: 375px;
  }
  @include media-breakpoint-up(xl) {
    bottom: 400px;
  }
}
.swiperContainer {
  width: 300px;
  position: relative;
  z-index: 9999;
  @include media-breakpoint-up(md) {
    width: 500px;
  }
  @include media-breakpoint-up(xl) {
    width: 650px;
  }
  .swiperSlide {
    display: flex;
    gap: 20px;
    @include media-breakpoint-up(md) {
      gap: 50px;
    }
    &.slideActive {
      .slideSidebar {
        .slideIcon {
          transition: transform 0.5s ease, opacity 0.5s ease;
          transform: translateY(0) rotate(0deg);
          opacity: 1;
        }
      }
      .slideContent {
        h1,
        h2,
        p {
          transition: transform 0.5s ease, opacity 0.5s ease;
          transform: translateY(0);
          opacity: 1;
          transition-delay: 0.1s;
        }
        h1,
        h2 {
          transition-delay: 0s;
          font-weight: 500;
        }
        p {
          transition-delay: 0.2s;
        }
      }
    }
    .slideSidebar {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 50px;
      @include media-breakpoint-up(md) {
        gap: 70px;
      }
      .slideIcon {
        transition: transform 0.5s ease, opacity 0.5s ease;
        opacity: 0;
        transform: translateY(20px) rotate(10deg);
        display: block;
        width: 60px;
        height: 60px;
        position: relative;
        margin-bottom: 30px;
        @include media-breakpoint-up(md) {
          width: 100px;
          height: 100px;
        }
        @include media-breakpoint-up(xl) {
          width: 120px;
          height: 120px;
        }
      }
      .swiperNavigation {
        display: flex;
        flex-direction: column;
        gap: 20px;
        @include media-breakpoint-up(md) {
          visibility: hidden;
        }
        button {
          padding: 0;
          cursor: pointer;
          position: relative;
          width: 36px;
          height: 36px;

          &:nth-child(2) {
            transform: rotate(180deg);
          }
        }
      }
    }
    .slideContent {
      display: flex;
      flex-direction: column;
      gap: 20px;
      @include media-breakpoint-up(md) {
        gap: 50px;
      }

      h1,
      h2,
      p {
        transition: transform 0.5s ease, opacity 0.5s ease;
        opacity: 0;
        transform: translateY(20px);
      }

      h1,
      h2 {
        transition-delay: 0s;
        @apply text-xl;
        @include media-breakpoint-up(md) {
          @apply text-4xl;
        }
        @include media-breakpoint-up(xl) {
          @apply text-5xl;
        }
      }

      p {
        transition-delay: 0.2s;
        color: theme("colors.stone.400");
        @apply text-sm;
        @include media-breakpoint-up(md) {
          @apply text-lg;
        }
        @include media-breakpoint-up(xl) {
          @apply text-xl;
        }
      }
    }
  }
}
.swiperNavigationDesktop {
  visibility: hidden;

  width: 70%;
  position: absolute;
  bottom: 300px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  z-index: 3;
  @include x-align;

  @include media-breakpoint-up(md) {
    visibility: visible;
  }
  @include media-breakpoint-up(lg) {
    width: 70%;
    bottom: 230px;
  }
  @include media-breakpoint-up(xl) {
    width: 60%;
    bottom: 300px;
    max-width: 1920px;
  }
  button {
    padding: 0;
    cursor: pointer;
    position: relative;
    width: 36px;
    height: 36px;
    @include media-breakpoint-up(md) {
      width: 50px;
      height: 50px;
    }
    @include media-breakpoint-up(xl) {
      width: 60px;
      height: 60px;
    }
    &:nth-child(2) {
      transform: rotate(180deg);
    }
  }
}
.swiperPagination {
  display: flex;
  align-items: center;
  @apply text-xs;
  color: theme("colors.stone.400");
  @include x-align;
  bottom: 25px;
  z-index: 3;
  @include media-breakpoint-up(md) {
    @apply text-sm;
  }
  span {
    width: 3px;
    height: 3px;
    display: block;
    background-color: theme("colors.stone.400");
    border-radius: 999px;
    margin: 0 5px;
  }
}
