@import "styles/core/_all.scss";
.container {
  z-index: 2;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100px;
  width: 100%;
  background: theme("colors.stone.800");
  background: linear-gradient(0deg, rgba(#292524, 1) 75%, rgba(#292524, 0) 100%);
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 10px 10px;
  @include media-breakpoint-up(md) {
    padding: 0 20px 20px;
  }
  @include media-breakpoint-up(lg) {
    background: transparent;
  }
  button {
    text-align: left;
    display: flex;
    align-items: center;
    gap: 10px;
    @apply text-sm;
    @include media-breakpoint-up(md) {
      @apply text-lg;
      gap: 20px;
    }
    &:nth-child(2) {
      flex-direction: row-reverse;
      text-align: right;
    }
    img {
      @include media-breakpoint-up(md) {
        width: 50px;
        height: 50px;
      }
    }
  }
}
